import React from 'react';
import { useVisualViewport } from '../../../hooks/useVisualViewport';

function SaveButton({ handleSave, actionSuccess, hasUnsavedChanges }) {
    const bottomOffset = useVisualViewport();

    return (
        <div 
            className="fixed right-4 z-10"
            style={{ 
                bottom: `max(16px, calc(2vh + ${bottomOffset}px))`,
                transition: 'all 0.1s'
            }}
        >
            <div>
                <button
                    type="button"
                    onClick={handleSave}
                    className={`flex items-center justify-center p-2 sm:p-3 rounded-lg ${
                        actionSuccess.save === true
                            ? 'bg-green-600 hover:bg-green-700'
                            : actionSuccess.save === 'error'
                            ? 'bg-red-600 hover:bg-red-700'
                            : hasUnsavedChanges
                            ? 'bg-blue-400 hover:bg-[#1976d2]'
                            : 'bg-[#242424] hover:bg-[#2a2a2a]'
                    } focus:outline-none focus:ring-2 focus:ring-[#1976d2] transition duration-150 ease-in-out shadow-lg border-2 ${
                        hasUnsavedChanges ? 'border-blue-300' : 'surface-border'
                    }`}
                    title={actionSuccess.save === true
                        ? 'Saved!'
                        : actionSuccess.save === 'error'
                        ? 'Error'
                        : actionSuccess.save === 'saving'
                        ? 'Saving...'
                        : 'Save'}
                    disabled={actionSuccess.save === 'saving'}
                >
                    <span className="material-icons text-white text-2xl">
                        {actionSuccess.save === true
                            ? 'check_circle'
                            : actionSuccess.save === 'error'
                            ? 'error'
                            : actionSuccess.save === 'saving'
                            ? 'hourglass_empty'
                            : hasUnsavedChanges
                            ? 'save_as'
                            : 'save'}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default SaveButton;