import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../../theme/darkTheme';
import SettingSection from '../SettingSection';

function SignoutSection({ localSettings, handleSettingChange }) {
    return (
        <SettingSection title="Signing out to:">
            <div className="flex items-center space-x-2 px-1">
                <span className="text-white whitespace-nowrap">Dr. </span>
                <input
                    type="text"
                    id="signoutPerson"
                    value={localSettings.signoutPerson}
                    onChange={(e) => handleSettingChange('signoutPerson', e.target.value)}
                    placeholder="Person name"
                    className="bg-[#2a2a2a] text-white p-2 rounded flex-grow h-10 min-w-0 border surface-border"
                />
                <span className="text-white whitespace-nowrap">on</span>
                <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={localSettings.signoutDate}
                            onChange={(newValue) => handleSettingChange('signoutDate', newValue)}
                            size="small"
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </div>
        </SettingSection>
    );
}

export default SignoutSection; 