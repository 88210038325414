import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
    },
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: '#e0e0e0',
                    '&.Mui-selected': {
                        backgroundColor: '#1976d2',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#1565c0',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e',
                    zIndex: 10001,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    borderRadius: '0.375rem',
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#2a2a2a',
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: '#2a2a2a',
                        color: 'white',
                        height: '40px',
                        minWidth: '120px',
                        cursor: 'pointer',
                        '& .MuiInputBase-input': {
                            cursor: 'pointer',
                            color: 'white',
                            padding: '8px'
                        },
                        '& .MuiIconButton-root': {
                            color: '#ababab'
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#323232'
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#323232'
                    }
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    '&:hover': {
                        backgroundColor: '#323232',
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#2a2a2a',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    color: '#e0e0e0',
                    '&:hover': {
                        backgroundColor: '#323232',
                    },
                    '&.Mui-focused': {
                        outline: '2px solid white',
                        outlineOffset: '2px',
                        borderRadius: '0.375rem',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #323232',
                    },
                    height: '40px',
                    minWidth: '120px',
                    width: '100%',
                    cursor: 'pointer',
                },
                input: {
                    padding: '8px',
                    height: '100%',
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: 10002,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 10002,
                },
            },
        },
        MuiDatePicker: {
            defaultProps: {
                slots: {
                    openPickerIcon: () => <span className="material-symbols-outlined">calendar_today</span>
                },
                slotProps: {
                    actionBar: {
                        actions: ['today']
                    }
                }
            }
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    zIndex: 10003,
                    "& .MuiPaper-root": {
                        backgroundColor: "#2a2a2a",
                        color: "white"
                    }
                }
            }
        },
    },
});

export default darkTheme;
