import { useState, useCallback, useEffect, useRef } from 'react';
import { snackbarError } from '../components/Snackbar';
import { useSettings } from '../context/SettingsContext';
import apiService from '../services/api';
import { useTextPostprocessing } from './useTextPostprocessing';

export const useNotes = () => {
    const { settings } = useSettings();
    const { postProcessText } = useTextPostprocessing();
    const [notes, setNotes] = useState([]);
    const [currentNote, setCurrentNote] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const initialFetchDone = useRef(false);
    const [changedFields, setChangedFields] = useState({});
    const [newNoteCreated, setNewNoteCreated] = useState(false);
    const [lastViewedNoteId, setLastViewedNoteId] = useState(null);

    const initializeNote = useCallback((note) => ({
        id: note.id || null,
        title: note.title || '',
        consultInfo: note.consultInfo || '',
        hpi: note.hpi || '',
        medicalHistory: note.medicalHistory || '',
        socialHistory: note.socialHistory || '',
        antimicrobials: note.antimicrobials || '',
        physicalExam: note.physicalExam || '',
        labs: note.labs || '',
        imaging: note.imaging || '',
        recommendations: note.recommendations || '',
        preset: note.preset || settings.defaultPreset || 'ID Consult',
        todos: note.todos || [],
        conversation: note.conversation || '',
    }), [settings.defaultPreset]);

    const selectNote = useCallback((note) => {
        setCurrentNote(initializeNote(note));
        setIsEditing(true);
        setLastViewedNoteId(note.id.toString());
    }, [initializeNote]);

    const fetchNotes = useCallback(async () => {
        if (initialFetchDone.current || !apiService.authToken) return;
        setIsLoading(true);
        setError(null);
        try {
            const fetchedNotes = await apiService.fetchNotes();
            const sortedNotes = fetchedNotes.map(initializeNote).sort((a, b) => a.order - b.order);
            setNotes(sortedNotes);

            // Fetch last viewed note ID
            const { lastViewedNoteId } = await apiService.fetchLastViewedNote();
            setLastViewedNoteId(lastViewedNoteId);

            // Select the last viewed note if it exists, otherwise select the first note
            const noteToSelect = sortedNotes.find(note => note.id.toString() === lastViewedNoteId) || sortedNotes[0];
            if (noteToSelect) {
                selectNote(noteToSelect);
            }

            initialFetchDone.current = true;
        } catch (error) {
            console.error('Error fetching notes:', error);
            if (error.response?.status !== 401) { // Don't show error for auth failures
                setError(`Error fetching notes: ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    }, [initializeNote, selectNote]);

    const reorderNotes = useCallback(async (reorderedNotes) => {
        // Optimistically update the local state
        setNotes(reorderedNotes);

        try {
            // Find notes that have changed position
            const changedNotes = reorderedNotes.filter((note, index) =>
                note.order !== index
            );

            if (changedNotes.length === 0) {
                // No changes, exit early
                return;
            }

            const noteOrders = changedNotes.map((note, index) => ({
                id: note.id,
                order: reorderedNotes.findIndex(n => n.id === note.id)
            }));

            await apiService.reorderNotes(noteOrders);
        } catch (error) {
            console.error('Error reordering notes:', error);
            setError(`Error reordering notes: ${error.message}`);
            // Revert to the original order if there's an error
            fetchNotes();
        }
    }, [fetchNotes]);

    useEffect(() => {
        let timeoutId;
        if (lastViewedNoteId) {
            timeoutId = setTimeout(() => {
                apiService.updateLastViewedNote(lastViewedNoteId).catch(error => {
                    console.error('Error updating last viewed note:', error);
                });
            }, 1000); // Debounce for 1 second
        }
        return () => clearTimeout(timeoutId);
    }, [lastViewedNoteId]);

    const resetInitialFetchDone = useCallback(() => {
        initialFetchDone.current = false;
    }, []);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    const createNewNote = useCallback(async (title = 'Untitled Note') => {
        const newNote = initializeNote({
            title,
            preset: settings.defaultPreset || 'ID Consult',
            id: Date.now(), // Temporary ID
            order: notes.length // Place the new note at the end
        });

        // Optimistically update local state
        setNotes(prevNotes => [...prevNotes, newNote].sort((a, b) => a.order - b.order));
        setCurrentNote(newNote);
        setIsEditing(true);
        setNewNoteCreated(true);
        setTimeout(() => setNewNoteCreated(false), 100);

        try {
            const savedNote = await apiService.createNote(newNote);
            const initializedNote = initializeNote(savedNote);

            // Update the note with the server-generated ID and any other changes
            setNotes(prevNotes => prevNotes.map(note =>
                note.id === newNote.id ? initializedNote : note
            ));
            setCurrentNote(initializedNote);

            return initializedNote;
        } catch (error) {
            console.error('Error creating new note:', error);
            // Revert the optimistic update
            setNotes(prevNotes => prevNotes.filter(note => note.id !== newNote.id));
            setCurrentNote(null);
            setIsEditing(false);
            setError(`Error creating new note: ${error.message}`);
            return null;
        }
    }, [initializeNote, settings.defaultPreset, notes.length]);

    const updateCurrentNoteLocally = useCallback((fieldOrUpdater, value) => {
        setCurrentNote(prevNote => {
            if (!prevNote) return null;
            if (typeof fieldOrUpdater === 'function') {
                return fieldOrUpdater(prevNote);
            }
            if (typeof fieldOrUpdater === 'object') {
                return { ...prevNote, ...fieldOrUpdater };
            }
            return { ...prevNote, [fieldOrUpdater]: value };
        });
        setChangedFields(prev => {
            if (typeof fieldOrUpdater === 'object') {
                return { ...prev, ...Object.fromEntries(Object.keys(fieldOrUpdater).map(key => [key, true])) };
            }
            if (typeof fieldOrUpdater === 'string') {
                return { ...prev, [fieldOrUpdater]: true };
            }
            return prev;
        });
    }, []);

    const saveCurrentNote = useCallback(async () => {
        if (!currentNote) return false;

        try {
            console.log("Attempting to save note...");
            const changedData = Object.keys(changedFields).reduce((acc, field) => {
                acc[field] = currentNote[field];
                return acc;
            }, {});

            // Only make API call if there are changes
            if (Object.keys(changedData).length > 0) {
                const savedNote = await apiService.updateNote(currentNote.id, changedData);
                console.log("Note saved successfully:", savedNote);
                setNotes(prevNotes =>
                    prevNotes.map(note => note.id === savedNote.id ? { ...note, ...savedNote } : note)
                );
            }
            
            setChangedFields({});
            return true;
        } catch (error) {
            console.error('Error saving note:', error);
            setError(`Error saving note: ${error.message}`);
            return false;
        }
    }, [currentNote, changedFields]);

    const deleteNote = useCallback(async (noteId) => {
        // Optimistically update the local state
        setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));
        if (currentNote && currentNote.id === noteId) {
            setCurrentNote(null);
            setIsEditing(false);
        }

        try {
            await apiService.deleteNote(noteId);
        } catch (error) {
            console.error('Error deleting note:', error);
            setError(`Error deleting note: ${error.message}`);
            // Revert the optimistic update by resetting the notes to the server state
            fetchNotes();
        }
    }, [currentNote, fetchNotes]);

    const deleteAllNotes = useCallback(async () => {
        // Optimistically update local state
        const previousNotes = notes;
        setNotes([]);
        setCurrentNote(null);
        setIsEditing(false);

        try {
            await apiService.deleteAllNotes();
        } catch (error) {
            console.error('Error deleting all notes:', error);
            // Revert optimistic update on error
            setNotes(previousNotes);
            
            let errorMessage = 'Error deleting all notes and todos';
            try {
                const parsedError = JSON.parse(error.message);
                errorMessage = `${parsedError.message}\n${parsedError.error}\n${parsedError.stack}`;
            } catch {
                errorMessage = error.toString();
            }
            setError(errorMessage);
        }
    }, [notes]);

    const summarizeNote = useCallback(async (section, preset) => {
        if (!currentNote) return null;

        try {
            const data = await apiService.summarizeNote({
                note: currentNote[section],
                title: currentNote.title,
                section,
                preset,
                aiProvider: settings.aiProvider
            });
            const summarizedContent = data.summary;

            if (summarizedContent !== currentNote[section]) {
                // Post-process the summary before updating the note
                const processedContent = postProcessText(summarizedContent);
                updateCurrentNoteLocally(section, processedContent);
                return processedContent;
            }
            return null; // Return null if no change
        } catch (error) {
            console.error(`Error in summarizeNote for ${section}:`, error);
            snackbarError(`Error summarizing note: ${error.message}`);
            return null;
        }
    }, [currentNote, settings.aiProvider, updateCurrentNoteLocally, postProcessText]);

    const resetLastViewedNoteId = useCallback(() => {
        setLastViewedNoteId(null);
    }, []);

    const createBulkNotes = useCallback(async (noteNames) => {
        try {
            // Create optimistic notes
            const optimisticNotes = noteNames.map(title => ({
                id: `temp-${Date.now()}-${Math.random()}`,
                title,
                preset: settings.defaultPreset || 'ID Consult',
                isOptimistic: true,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }));

            // Add optimistic notes to the state
            setNotes(prevNotes => [...optimisticNotes, ...prevNotes]);

            const createdNotes = await apiService.createBulkNotes(noteNames);

            // Replace optimistic notes with real ones
            setNotes(prevNotes => {
                const nonOptimisticNotes = prevNotes.filter(note => !note.isOptimistic);
                return [...createdNotes, ...nonOptimisticNotes];
            });

            return createdNotes;
        } catch (error) {
            // Remove optimistic notes on error
            setNotes(prevNotes => prevNotes.filter(note => !note.isOptimistic));
            console.error('Error creating bulk notes:', error);
            snackbarError('Failed to create notes');
            throw error;
        }
    }, [settings.defaultPreset]);

    return {
        notes,
        setNotes,
        currentNote,
        setCurrentNote,
        isEditing,
        error,
        isLoading,
        selectNote,
        createNewNote,
        newNoteCreated,
        updateCurrentNoteLocally,
        saveCurrentNote,
        changedFields,
        setChangedFields,
        deleteNote,
        deleteAllNotes,
        summarizeNote,
        reorderNotes,
        resetInitialFetchDone,
        fetchNotes: useCallback(() => {
            initialFetchDone.current = false;
            fetchNotes();
        }, [fetchNotes]),
        lastViewedNoteId,
        resetLastViewedNoteId,
        createBulkNotes,
    };
};