import React from 'react';
import SettingSection from '../SettingSection';

const presets = [
    { full: 'ID Consult', short: 'ID' },
    { full: 'ID Follow Up', short: 'Follow' },
    { full: 'Outpatient', short: 'Outpt' }
];

function PresetSection({ localSettings, handleSettingChange }) {
    return (
        <SettingSection title="Default Preset">
            <div className="inline-flex rounded-md shadow-sm" role="group">
                {presets.map((preset, index) => (
                    <button
                        key={preset.full}
                        type="button"
                        onClick={() => handleSettingChange('defaultPreset', preset.full)}
                        className={`
                            px-4 py-2 text-sm font-medium
                            ${index === 0 ? 'rounded-l-lg' : ''}
                            ${index === presets.length - 1 ? 'rounded-r-lg' : ''}
                            ${localSettings.defaultPreset === preset.full
                                ? 'bg-[#1976d2] text-white'
                                : 'bg-[#2a2a2a] text-[#e0e0e0] hover:bg-[#323232] hover:text-white'
                            }
                            focus:z-10 focus:ring-1 focus:ring-white focus:text-white flash-button
                            transition-colors duration-200 ease-in-out
                            border-t border-b ${index === 0 ? 'border-l' : ''} ${index === presets.length - 1 ? 'border-r' : ''}
                            border-[#404040]
                        `}
                    >
                        <span className="hidden sm:inline">{preset.full}</span>
                        <span className="sm:hidden">{preset.short}</span>
                    </button>
                ))}
            </div>
        </SettingSection>
    );
}

export default PresetSection; 