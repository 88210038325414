import React, { useState } from 'react';
import { useSettings } from '../../../context/SettingsContext';
import apiService from '../../../services/api';


function MessageButton({ note, currentPreset }) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const { settings } = useSettings();

    const handleOpenDialog = () => {
        setIsOpen(true);
        setMessage(''); // Reset the message when opening the dialog
        setError(null); // Also reset any previous errors
    };

    const handleGenerateMessage = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await apiService.generateMessage(note.recommendations, settings.aiProvider);
            setMessage(data.message);
        } catch (error) {
            console.error('Error generating message:', error);
            setError('Failed to generate message. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyMessage = () => {
        navigator.clipboard.writeText(message);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    if (!['ID Follow Up', 'ID Consult'].includes(currentPreset)) {
        return null;
    }

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className="flex items-center justify-center px-3 py-1.5 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out"
                title="Generate Message"
            >
                <span className="material-symbols-outlined text-white text-base mr-1">
                    forum
                </span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline">Message</span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap sm:hidden">Msg</span>
            </button>
            {isOpen && (
                <div className="fixed inset-0 app-bg bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="surface-bg p-4 sm:p-6 rounded-lg shadow-xl border surface-border w-full max-w-2xl relative">
                        <button
                            onClick={() => setIsOpen(false)}
                            className="absolute top-2 right-2 text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md p-1 focus:outline-none"
                            aria-label="Close"
                        >
                            <span className="material-icons">close</span>
                        </button>
                        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">Generate Message for Team</h2>
                        <button
                            onClick={handleGenerateMessage}
                            disabled={isLoading}
                            className="mb-3 sm:mb-4 px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white border surface-border flash-button"
                        >
                            {isLoading ? (
                                <>
                                    <span className="material-icons text-white text-base animate-spin mr-1">hourglass_empty</span>
                                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generating...</span>
                                </>
                            ) : (
                                <>
                                        <span className="material-icons text-white text-base mr-1">auto_fix_high</span>
                                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generate Message</span>
                                </>
                            )}
                        </button>
                        {error && <p className="text-red-500 mb-2">{error}</p>}
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="input-base w-full h-36 sm:h-48 p-2 rounded mb-3 sm:mb-4"
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={handleCopyMessage}
                                className="inline-flex items-center justify-center px-2 py-1 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out border"
                                title="Copy"
                            >
                                <span className="material-icons text-white text-base">
                                    {copySuccess ? 'check_circle' : 'content_copy'}
                                </span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline ml-1">Copy</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MessageButton;