import React from 'react';
import SettingSection from '../SettingSection';

const aiProviders = [
    { value: 'claude', label: 'Claude' },
    { value: 'azure', label: 'Azure' }
];

function AIProviderSection({ localSettings, handleSettingChange }) {
    return (
        <SettingSection title="AI Provider">
            <div className="slider-container">
                <div
                    className="slider-track"
                    style={{
                        transform: `translateX(${localSettings.aiProvider === 'azure' ? '100%' : '0'})`
                    }}
                />
                <div className="slider-buttons">
                    {aiProviders.map((provider) => (
                        <button
                            key={provider.value}
                            onClick={() => handleSettingChange('aiProvider', provider.value)}
                            className={`slider-button ${localSettings.aiProvider === provider.value ? 'active' : ''}`}
                        >
                            {provider.label}
                        </button>
                    ))}
                </div>
            </div>
        </SettingSection>
    );
}

export default AIProviderSection; 