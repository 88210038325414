import React, { useState } from 'react';
import SettingsDialog from '../../Settings/SettingsDialog';

function SettingsButton() {
    const [isOpen, setIsOpen] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);

    const handleOpenDialog = () => {
        setIsSpinning(true);
        setTimeout(() => setIsSpinning(false), 300);
        setIsOpen(true);
    };

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded bg-[#2a2a2a] text-white hover:bg-[#323232] flash-button border surface-border transition duration-150 ease-in-out flex items-center justify-center"
                title="Settings"
            >
                <span className={`material-symbols-outlined text-lg sm:text-xl ${isSpinning ? 'animate-spin-once' : ''}`}>settings</span>
            </button>
            {isOpen && <SettingsDialog isOpen={isOpen} setIsOpen={setIsOpen} />}
        </>
    );
}

export default SettingsButton; 