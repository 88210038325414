import React, { useRef, useEffect, useState } from 'react';

function NoteEditorTabs({ activeTab, setActiveTab, todoCount }) {
    const tabsRef = useRef([]);
    const [tabIndicatorStyle, setTabIndicatorStyle] = useState({});

    useEffect(() => {
        const updateTabIndicator = () => {
            const activeTabIndex = ['editor', 'todo', 'insights', 'scribe'].indexOf(activeTab);
            const activeTabElement = tabsRef.current[activeTabIndex];
            if (activeTabElement) {
                const indicatorWidth = activeTabElement.offsetWidth;
                setTabIndicatorStyle({
                    left: `${activeTabElement.offsetLeft}px`,
                    width: `${indicatorWidth}px`,
                });
            }
        };

        updateTabIndicator();
    }, [activeTab, todoCount]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="mb-4">
            <div className="relative border-b border-[#2a2a2a]">
                <div className="flex">
                    <button
                        ref={(el) => (tabsRef.current[0] = el)}
                        onClick={() => handleTabClick('editor')}
                        className={`py-2 px-4 text-sm font-medium flash-button ${activeTab === 'editor' ? 'text-[#1976d2]' : 'text-[#ababab]'
                            } focus:outline-none`}
                    >
                        Editor
                    </button>
                    <button
                        ref={(el) => (tabsRef.current[1] = el)}
                        onClick={() => handleTabClick('todo')}
                        className={`py-2 px-4 text-sm font-medium flash-button ${activeTab === 'todo' ? 'text-[#1976d2]' : 'text-[#ababab]'
                            } focus:outline-none`}
                    >
                        {todoCount > 0 ? (
                            <>
                                Todos <span className="text-white">({todoCount})</span>
                            </>
                        ) : (
                            'Todos'
                        )}
                    </button>
                    <button
                        ref={(el) => (tabsRef.current[2] = el)}
                        onClick={() => handleTabClick('insights')}
                        className={`py-2 px-4 text-sm font-medium flash-button ${activeTab === 'insights' ? 'text-[#1976d2]' : 'text-[#ababab]'
                            } focus:outline-none`}
                    >
                        Insights
                    </button>
                    <button
                        ref={(el) => (tabsRef.current[3] = el)}
                        onClick={() => handleTabClick('scribe')}
                        className={`py-2 px-4 text-sm font-medium flash-button ${activeTab === 'scribe' ? 'text-[#1976d2]' : 'text-[#ababab]'
                            } focus:outline-none`}
                    >
                        Scribe
                    </button>
                </div>
                <div
                    className="absolute bottom-0 left-0 h-0.5 bg-[#1976d2] transition-all duration-300"
                    style={tabIndicatorStyle}
                />
            </div>
        </div>
    );
}

export default NoteEditorTabs;
