import React from 'react';

function SummarizeAllButton({ handleSummarizeAll, isSummarizing }) {
    return (
        <button
            type="button"
            onClick={handleSummarizeAll}
            className={`flex items-center justify-center px-4 py-2 rounded-md border ${
                isSummarizing 
                    ? 'bg-[#323232] border-[#424242]' 
                    : 'primary-button'
            } focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
            title="Format All"
            disabled={isSummarizing}
        >
            <span className={`material-icons text-white text-base mr-2 ${isSummarizing ? 'animate-spin' : ''}`}>
                {isSummarizing ? 'hourglass_empty' : 'auto_fix_high'}
            </span>
            <span className="text-sm text-white">
                {isSummarizing ? 'Formatting...' : 'Format All'}
            </span>
        </button>
    );
}

export default SummarizeAllButton;