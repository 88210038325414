import React, { useState } from 'react';
import { useSettings } from '../../../context/SettingsContext';
import apiService from '../../../services/api';

function MultiInsertButton({ note, currentPreset, updateNote }) {
    const { settings } = useSettings();
    const [isOpen, setIsOpen] = useState(false);
    const [unstructuredNotes, setUnstructuredNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleOpenDialog = () => {
        setIsOpen(true);
        setError(null);
    };

    const handleMultiInsert = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const sectionKeys = Object.keys(note).filter(key =>
                ['consultInfo', 'hpi', 'medicalHistory', 'socialHistory', 'antimicrobials', 'physicalExam', 'labs', 'imaging', 'recommendations'].includes(key)
            );

            const data = await apiService.multiInsert(
                unstructuredNotes,
                sectionKeys,
                currentPreset,
                settings.aiProvider
            );

            // Update each section of the note
            Object.entries(data.structuredJson).forEach(([key, value]) => {
                if (note[key] !== undefined) {
                    const currentContent = note[key].trim();
                    const newContent = value.trim();
                    const updatedContent = currentContent
                        ? `${currentContent}\n\n${newContent}`
                        : newContent;
                    updateNote(key, updatedContent);
                }
            });

            setIsOpen(false);
        } catch (error) {
            console.error('Error in multi-insert:', error);
            setError('Failed to process notes. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className={`flex items-center justify-center px-4 py-2 rounded-md border ${
                    isLoading 
                        ? 'bg-[#323232] border-[#424242]' 
                        : 'primary-button'
                } focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out ml-2`}
                title="Multi-Insert Notes"
                disabled={isLoading}
            >
                <span className="material-symbols-outlined text-white text-base mr-2">
                    {isLoading ? 'hourglass_empty' : 'note_add'}
                </span>
                <span className="text-sm text-white">
                    {isLoading ? 'Processing...' : 'Multi-Insert'}
                </span>
            </button>
            {isOpen && (
                <div className="fixed inset-0 app-bg bg-opacity-50 flex items-center justify-center z-50">
                    <div className="surface-bg p-6 rounded-lg shadow-xl border surface-border w-full max-w-2xl relative">
                        <button
                            onClick={() => setIsOpen(false)}
                            className="absolute top-2 right-2 text-[#ababab] hover:text-white focus:outline-none focus:ring-1 focus:ring-white"
                            aria-label="Close"
                        >
                            <span className="material-icons">close</span>
                        </button>
                        <h2 className="text-xl font-bold mb-4 text-gray-100 text-center">Insert into Note Sections</h2>
                        <textarea
                            value={unstructuredNotes}
                            onChange={(e) => setUnstructuredNotes(e.target.value)}
                            className="input-base w-full h-64 p-2 rounded mb-4"
                            placeholder="Compose notes..."
                        />
                        {error && <p className="text-red-500 mb-2">{error}</p>}
                        <div className="flex justify-end">
                            <button
                                onClick={handleMultiInsert}
                                disabled={isLoading}
                                className="mb-4 px-2 py-1 bg-[#2a2a2a] hover:bg-[#323232] border surface-border text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white flash-button"
                            >
                                {isLoading ? (
                                    <>
                                        <span className="material-icons text-white text-base animate-spin mr-1">hourglass_empty</span>
                                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Processing...</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="material-symbols-outlined text-white text-base mr-1">variable_insert</span>
                                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Insert Notes</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MultiInsertButton;