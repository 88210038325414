import React, { useState } from 'react';
import BulkNoteCreationModal from './BulkNoteCreationModal';

function SidebarButtons({ 
    isPanelCollapsed, 
    handleNewNote, 
    handleSignout, 
    isNewNoteAnimating,
    notesExist,
    handleBulkNoteCreation 
}) {
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);

    const handleNewNoteClick = () => {
        handleNewNote();
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        setIsBulkModalOpen(true);
    };

    if (isPanelCollapsed) {
        return (
            <>
                <button
                    onClick={handleNewNoteClick}
                    onContextMenu={handleContextMenu}
                    title="New Note (Right-click for multiple)"
                    className={`w-full flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white ${
                        isNewNoteAnimating ? 'animate-click' : ''
                    }`}
                >
                    <span className="material-symbols-outlined">note_stack_add</span>
                </button>
                <BulkNoteCreationModal 
                    isOpen={isBulkModalOpen}
                    onClose={() => setIsBulkModalOpen(false)}
                    onCreateNotes={handleBulkNoteCreation}
                />
            </>
        );
    }

    return notesExist ? (
        <>
            <div className="flex space-x-2">
                <button
                    onClick={handleSignout}
                    title="Generate Signout"
                    className="flex-1 flex items-center justify-center p-2 bg-[#2e7d32] hover:bg-[#388e3c] rounded transition duration-150 ease-in-out text-white flash-button border border-[#4caf50]"
                >
                    <span className="material-icons">assignment_ind</span>
                    Signout
                </button>
                <button
                    onClick={handleNewNoteClick}
                    onContextMenu={handleContextMenu}
                    title="New Note (Right-click for multiple)"
                    className={`flex-1 flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button ${
                        isNewNoteAnimating ? 'animate-click' : ''
                    }`}
                >
                    <span className="material-symbols-outlined">note_stack_add</span>
                    New
                </button>
            </div>
            <BulkNoteCreationModal 
                isOpen={isBulkModalOpen}
                onClose={() => setIsBulkModalOpen(false)}
                onCreateNotes={handleBulkNoteCreation}
            />
        </>
    ) : (
        <>
            <button
                onClick={handleNewNoteClick}
                onContextMenu={handleContextMenu}
                title="New Note (Right-click for multiple)"
                className={`w-full flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button ${
                    isNewNoteAnimating ? 'animate-click' : ''
                }`}
            >
                <span className="material-symbols-outlined">note_stack_add</span>
                New Note
            </button>
            <BulkNoteCreationModal 
                isOpen={isBulkModalOpen}
                onClose={() => setIsBulkModalOpen(false)}
                onCreateNotes={handleBulkNoteCreation}
            />
        </>
    );
}

export default SidebarButtons; 