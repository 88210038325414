import { createSignature } from './signatureUtils';

export const sectionHeadings = {
    consultInfo: ['Consulting Physician:', 'Reason for Consultation:',  'Consulting Service:'],
    hpi: ['History of Present Illness:', 'Interval History:'],
    medicalHistory: ['Medical History:', 'Surgical History:', 'Family History:', 'Antimicrobial Allergies:', 'Allergies:'],
    socialHistory: ['Social History:'],
    antimicrobials: ['Antimicrobials:', 'Prior:'],
    physicalExam: [
        'Physical Examination:',
        'Vital Signs:',
        'Tmax:',
        'HR:',
        'BP:',
        'RR:',
        'SpO2:',
        'General:',
        'HEENT:',
        'Lymphatic:',
        'CVS:',
        'Chest:',
        'Abdomen:',
        'Genitourinary:',
        'Musculoskeletal:',
        'Skin:',
        'Neurologic:',
        'Psychiatric:',
        'Extremities:',
        'Lines:',
    ],
    labs: ['Labs:', 'Microbiology:'],
    imaging: ['Radiology:', 'Cardiology:'],
    recommendations: ['Assessment and Recommendations:', "Assessment and Plan:", "Billing:"],
};

export const formatTextWithHeadings = (text, section, format = 'html') => {
    const lines = text.split('\n');
    const headings = section === 'heading' ? [text] : (sectionHeadings[section] || []);

    return lines.map(line => {
        let formattedLine = line;
        const matchedHeading = headings.find(heading => line.toLowerCase().startsWith(heading.toLowerCase()));
        if (format === 'html') {
            if (section === 'heading') {
                formattedLine = `<b><u>${formattedLine}</u></b>`;
            } else {
                headings.forEach(heading => {
                    const regex = new RegExp(`(${heading})`, 'gi');
                    formattedLine = formattedLine.replace(regex, '<b>$1</b>');
                });
            }
        } else if (format === 'docx') {
            if (matchedHeading) {
                formattedLine = { heading: matchedHeading, text: line };
            } else {
                formattedLine = { text: line };
            }
        }
        return formattedLine;
    });
};

export const copyToClipboard = async (content, isHtml = false) => {
    const styledContent = isHtml ? content : content.split('<br>').map(line => 
        `<p style="font-family: Calibri, sans-serif; font-size: 11pt; margin: 0;">${line}</p>`
    ).join('');

    const htmlContent = `
        <html>
            <body>
                <!--StartFragment-->${styledContent}<!--EndFragment-->
            </body>
        </html>
    `;

    // Replace <br> tags with newline characters for plain text
    const plainTextContent = content.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '');

    const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([plainTextContent], { type: 'text/plain' })
    });

    await navigator.clipboard.write([clipboardItem]);
};

export const formatNoteForExport = (note, currentPreset, presetSections, format = 'html', settings) => {
    let heading = '';
    if (currentPreset === 'ID Consult') {
        heading = 'Infectious Disease Initial Consultation';
    } else if (currentPreset === 'ID Follow Up') {
        heading = 'Infectious Disease Consultation Follow Up';
    }

    const sections = heading ? ['heading', ...presetSections[currentPreset]] : presetSections[currentPreset];

    const formattedSections = sections
        .map(section => {
            let sectionContent = note[section] || '';
            if (section === 'heading') {
                sectionContent = heading;
            }
            if (sectionContent.trim() === '') return '';
            const formattedContent = formatTextWithHeadings(sectionContent, section, format);
            return format === 'html'
                ? `${formattedContent.join('<br>')}<br><br>`
                : { section, content: formattedContent };
        })
        .filter(content => content !== '');

    // Add signature
    const signature = createSignature(currentPreset, settings);
    const formattedSignature = formatTextWithHeadings(signature, 'recommendations', format);
    formattedSections.push(format === 'html'
        ? `${formattedSignature.join('<br>')}<br>`
        : { section: 'signature', content: formattedSignature });

    return formattedSections;
};