import React from 'react';

function HashProblemsButton({ assessment, updateNote }) {
    const handleClick = () => {
        // First normalize line endings and remove consecutive empty lines
        const normalizedText = assessment.replace(/\n{3,}/g, '\n\n');
        
        const blocks = normalizedText
            .split('\n\n')
            .filter(block => block.trim());
            
        const hashedBlocks = blocks.map(block => {
            const lines = block.split('\n');
            if (lines.length === 0) return '';
            
            // Only hash the first non-empty line of each block
            const firstNonEmptyIndex = lines.findIndex(line => line.trim());
            if (firstNonEmptyIndex !== -1 && !lines[firstNonEmptyIndex].trim().startsWith('#')) {
                lines[firstNonEmptyIndex] = `#${lines[firstNonEmptyIndex].trim()}`;
            }
            return lines.join('\n');
        });

        const newText = hashedBlocks.join('\n\n\n');
        // Only update if the text has actually changed
        if (newText !== assessment) {
            updateNote(newText);
        }
    };

    return (
        <button
            type="button"
            onClick={handleClick}
            className="flex items-center justify-center px-2 py-1 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
            title="Hash all problems"
        >
            <span className="material-symbols-outlined text-white text-base">tag</span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">Hash</span>
        </button>
    );
}

export default HashProblemsButton; 