import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class ApiService {
    constructor() {
        this._authToken = null;
        this.axiosInstance = axios.create({
            baseURL: SERVER_URL,
            timeout: 120000,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Add response interceptor for handling auth errors
        this.axiosInstance.interceptors.response.use(
            response => response,
            error => {
                // Only handle 401s if we have an auth token (i.e., we're supposed to be authenticated)
                if (error.response?.status === 401 && this._authToken) {
                    sessionStorage.removeItem('token');
                    window.location.reload();
                }
                return Promise.reject(error);
            }
        );
    }

    get authToken() {
        return this._authToken;
    }

    setAuthToken(token) {
        this._authToken = token;
        if (token) {
            this.axiosInstance.defaults.headers.common['x-auth-token'] = token;
        } else {
            delete this.axiosInstance.defaults.headers.common['x-auth-token'];
        }
    }

    // Notes API
    async fetchNotes() {
        const response = await this.axiosInstance.get('/api/notes');
        return response.data;
    }

    async fetchLastViewedNote() {
        const response = await this.axiosInstance.get('/api/notes/last-viewed');
        return response.data;
    }

    async createNote(note) {
        const response = await this.axiosInstance.post('/api/notes', note);
        return response.data;
    }

    async updateNote(noteId, data) {
        const response = await this.axiosInstance.patch(`/api/notes/${noteId}`, data);
        return response.data;
    }

    async deleteNote(noteId) {
        const response = await this.axiosInstance.delete(`/api/notes/${noteId}`);
        return response.data;
    }

    async deleteAllNotes() {
        const response = await this.axiosInstance.delete('/api/notes');
        return response.data;
    }

    async reorderNotes(noteOrders) {
        const response = await this.axiosInstance.put('/api/notes/reorder', { noteOrders });
        return response.data;
    }

    async updateLastViewedNote(noteId) {
        const response = await this.axiosInstance.post('/api/notes/last-viewed', { noteId });
        return response.data;
    }

    async createBulkNotes(notes) {
        const response = await this.axiosInstance.post('/api/notes/bulk', { notes });
        return response.data;
    }

    async summarizeNote(data) {
        const response = await this.axiosInstance.post('/api/notes/summarize', data);
        return response.data;
    }

    async multiInsert(unstructuredNotes, sectionKeys, currentPreset, aiProvider) {
        const response = await this.axiosInstance.post('/api/notes/multi-insert', {
            unstructuredNotes,
            sectionKeys,
            currentPreset,
            aiProvider
        });
        return response.data;
    }

    // Message API
    async generateMessage(recommendations, aiProvider) {
        const response = await this.axiosInstance.post('/api/notes/generate-message', {
            recommendations,
            aiProvider
        });
        return response.data;
    }

    // Auth API
    async login(username, password, deviceToken = null) {
        const response = await this.axiosInstance.post('/api/login', {
            username,
            password,
            deviceToken
        });
        return response.data;
    }

    async verifyOTP(username, otp) {
        const response = await this.axiosInstance.post('/api/verify-otp', {
            username,
            otp
        });
        return response.data;
    }

    async logout() {
        const response = await this.axiosInstance.post('/api/logout');
        return response.data;
    }

    // Settings API
    async fetchSettings() {
        const response = await this.axiosInstance.get('/api/settings');
        return response.data;
    }

    async updateSettings(settings) {
        const response = await this.axiosInstance.post('/api/settings', settings);
        return response.data;
    }

    async pushTranscription(transcription, currentPreset) {
        const response = await this.axiosInstance.post('/api/notes/push-transcription', {
            transcription,
            currentPreset
        });
        return response.data;
    }

    async transcribeAudio(audioBlob) {
        const response = await this.axiosInstance.post('/api/notes/transcribe', audioBlob, {
            headers: {
                'Content-Type': 'audio/webm',
            },
        });
        return response.data;
    }

    async getPresetFilter() {
        const settings = await this.fetchSettings();
        return settings.presetFilter || 'all';
    }

    async savePresetFilter(filter) {
        return await this.updateSettings({ presetFilter: filter });
    }
}

// Create and export a singleton instance
const apiService = new ApiService();
export default apiService; 