import React from 'react';

function SettingSection({ title, children }) {
    return (
        <div className="mb-8 bg-[#242424] rounded-lg p-6 shadow-md border surface-border">
            <h3 className="text-lg font-semibold mb-4 text-white border-b border-[#323232] pb-2">{title}</h3>
            {children}
        </div>
    );
}

export default SettingSection; 