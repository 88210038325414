import React, { useState } from 'react';
import { snackbarMessage, snackbarError } from '../../Snackbar';
import { sectionLabels } from '../../NoteEditor/NoteEditorSections';
import apiService from '../../../services/api';

function ScribePushToNote({ transcription, note, currentPreset, updateNote }) {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [sectionData, setSectionData] = useState({});

    const fetchStructuredData = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.axiosInstance.post('/api/notes/push-transcription', {
                transcription,
                currentPreset
            });
            
            setSectionData(response.data.structuredJson);
            setShowModal(true);
        } catch (error) {
            console.error('Error in push-to-note:', error);
            snackbarError('Failed to process transcription. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePushToNote = () => {
        try {
            // Update each section of the note
            Object.entries(sectionData).forEach(([key, value]) => {
                if (note[key] !== undefined) {
                    const currentContent = note[key].trim();
                    const newContent = value.trim();
                    const updatedContent = currentContent
                        ? `${currentContent}\n\n${newContent}`
                        : newContent;
                    updateNote(key, updatedContent);
                }
            });

            setShowModal(false);
            snackbarMessage('Transcription successfully pushed to note');
        } catch (error) {
            console.error('Error pushing to note:', error);
            snackbarError('Failed to update note. Please try again.');
        }
    };

    const handleSectionChange = (key, value) => {
        setSectionData(prev => ({
            ...prev,
            [key]: value
        }));
    };

    return (
        <>
            <button
                onClick={fetchStructuredData}
                disabled={isLoading}
                className={`flex items-center justify-center px-2 py-1 rounded-md ${
                    isLoading 
                        ? 'bg-[#1976d2] bg-opacity-20 border-[#1976d2]' 
                        : 'base-button'
                } border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
                title="Summarize Conversation"
            >
                <span className={`material-icons text-white text-base ${isLoading ? 'animate-spin' : ''}`}>
                    {isLoading ? 'hourglass_empty' : 'auto_fix_high'}
                </span>
                <span className="text-xs text-gray-300 whitespace-nowrap ml-1 hidden sm:inline">
                    {isLoading ? 'Processing...' : 'Summarize'}
                </span>
            </button>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="surface-bg surface-border rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-xl text-white">Review Additions:</h2>
                            <button
                                onClick={() => setShowModal(false)}
                                className="text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md p-1 focus:outline-none"
                            >
                                <span className="material-icons">close</span>
                            </button>
                        </div>
                        
                        {Object.entries(sectionData).map(([key, value]) => (
                            <div key={key} className="mb-6">
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    {sectionLabels[key] || key.replace(/([A-Z])/g, ' $1').trim()}:
                                </label>
                                <textarea
                                    value={value}
                                    onChange={(e) => handleSectionChange(key, e.target.value)}
                                    className="block w-full rounded-md input-base text-white transition duration-150 ease-in-out shadow-sm px-3 py-2 h-32"
                                />
                            </div>
                        ))}

                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setShowModal(false)}
                                className="flex items-center justify-center px-4 py-2 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                            >
                                <span className="material-icons text-white text-base mr-1">close</span>
                                <span className="text-sm text-white">Cancel</span>
                            </button>
                            <button
                                onClick={handlePushToNote}
                                className="flex items-center justify-center px-4 py-2 rounded-md bg-[#1976d2] border border-[#2196f3] hover:bg-[#2196f3] hover:border-[#42a5f5] focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out"
                            >
                                <span className="material-icons text-white text-base mr-1">compare_arrows</span>
                                <span className="text-sm text-white">Push to Note</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ScribePushToNote;